/* aka card-body padding */
.fa-1x {
  font-size: 1.3rem;
}

.fa-2x {
  font-size: 1.6rem;
}

.fa-3x {
  font-size: 2rem;
}

.fa-4x {
  font-size: 3rem;
}

body.background-image {
  background: url("../images/body_bg_img.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.main-container {
  background-color: white;
  padding-top: 2rem;
  margin-top: 5rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 900;
  line-height: 1.2;
  color: inherit;
  text-transform: uppercase;
}

h1, .h1 {
  font-size: 1.6rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1.3rem;
}

h5, .h5 {
  font-size: 1.2rem;
}

h6, .h6 {
  font-size: 1rem;
}

a {
  outline: 0;
}

/***************/
/*** BUTTONS ***/
/***************/
.btn {
  border-radius: 0;
}

.btn.btn-primary {
  color: #001934;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  border-radius: 0;
}

.btn.btn-primary:hover {
  color: #001934;
  background-color: silver;
  border-color: silver;
}

.btn.btn-secondary {
  color: white;
  background-color: #067f9f;
  border-color: #067f9f;
  border-radius: 0;
}

.btn.btn-secondary:hover {
  color: white;
  background-color: #034455;
  border-color: #034455;
}

/**************/
/*** BADGES ***/
/**************/
.badge {
  border-radius: 0;
}

.badge.badge-info {
  background-color: #17a2b8;
  color: #001934;
}

.badge.badge-success {
  background-color: #c3e6cb;
  color: #001934;
}

.badge.badge-warning {
  background-color: #ffeeba;
  color: #001934;
}

.badge.badge-primary {
  font-size: 0.9rem;
  color: #001934;
  padding: 0;
  background-color: transparent;
}

.badge.badge-secondary {
  font-weight: 400;
  color: white;
  background-color: #067f9f;
  font-size: 1rem;
  line-height: 1.5;
}

.badge.badge-secondary-options {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.badge.badge-tertiary {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.badge.badge-stage {
  white-space: normal;
}

/**************/
/*** PICTOS ***/
/**************/
.picto-wave:before {
  content: "";
  display: inline-block;
  background: url("../images/picto-wave.png") no-repeat;
  float: left;
  width: 35px;
  height: 30px;
  margin: 5px 6px 0 0;
}

/*************/
/*** NAVBAR ***/
/*************/
.navbar .nav-link .fa-user, .navbar nav-link .fa-shopping-cart {
  color: #067f9f !important;
}

.navbar.bg-light {
  background-color: #001934 !important;
}

.navbar.navbar-light .navbar-text {
  color: white;
}

.navbar.navbar-light .navbar-text a {
  color: white;
}

.navbar.navbar-light .navbar-text a:hover, .navbar.navbar-light .navbar-text a:focus {
  color: white;
  text-decoration: none;
}

.navbar.navbar-light .navbar-nav .nav-link {
  color: white;
}

.navbar.navbar-light .navbar-nav .nav-link:hover, .navbar.navbar-light .navbar-nav .nav-link:focus {
  color: white;
}

.navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .navbar-toggler {
  background-color: #067f9f;
}

.navbar .navbar-collapse.show {
  border-top: 1px solid white;
}

/*************/
/*** CARDS ***/
/*************/
.card {
  border-radius: 0;
}

.card .card-header {
  border-radius: 0;
}

.card.stage-critere, .card.stage-item {
  background-color: transparent;
  border: 0px solid #e6e6e6;
}

.card.stage-critere .card-header, .card.stage-item .card-header {
  background-color: #e6e6e6;
  border-bottom: 0 solid #e6e6e6;
}

.card.stage-item .card-header {
  color: white;
}

.card .card-body {
  padding: 0;
}

.card .card-body .card-footer {
  padding: 3rem 0.3rem;
  background-color: transparent;
  border-top: 1px solid transparent;
}

.card.connexion-client, .card.paiement-client, .card.mon-compte, .card.panier-content, .card.card-form, .card.place-stage {
  background-color: transparent;
  border: 1px solid #e6e6e6;
}

.card.connexion-client .card-header, .card.paiement-client .card-header, .card.mon-compte .card-header, .card.panier-content .card-header, .card.card-form .card-header, .card.place-stage .card-header {
  background-color: #e6e6e6;
  border-bottom: 0 solid #e6e6e6;
  padding: 0.75rem 1rem;
}

.card.connexion-client .card-body, .card.paiement-client .card-body, .card.mon-compte .card-body, .card.panier-content .card-body, .card.card-form .card-body, .card.place-stage .card-body {
  padding: 1rem;
}

.card.connexion-client .card-body .detail-panier .options-reservation, .card.paiement-client .card-body .detail-panier .options-reservation, .card.mon-compte .card-body .detail-panier .options-reservation, .card.panier-content .card-body .detail-panier .options-reservation, .card.card-form .card-body .detail-panier .options-reservation, .card.place-stage .card-body .detail-panier .options-reservation {
  background-color: white;
}

.card.connexion-client .card-body .detail-panier .btn, .card.paiement-client .card-body .detail-panier .btn, .card.mon-compte .card-body .detail-panier .btn, .card.panier-content .card-body .detail-panier .btn, .card.card-form .card-body .detail-panier .btn, .card.place-stage .card-body .detail-panier .btn {
  vertical-align: baseline;
}

.card.mon-compte .card-body .legend_item {
  height: 15px;
  width: 35px;
  margin-right: 10px;
  float: left;
}

.card.mon-compte .card {
  background-color: transparent;
  margin: 1rem;
}

.card.mon-compte .card .card-body {
  padding: 1rem;
}

/*************/
/*** FORMS ***/
/*************/
form .form-control {
  background-color: #e6e6e6;
  border-radius: 0;
}

form .custom-control-label::before {
  border-radius: 0 !important;
}

form .required-star {
  color: red;
}

.stage-view form .custom-control-inline {
  margin-right: .5rem;
}

.stage-critere .custom-label,
.stage-critere legend.col-form-label {
  text-transform: uppercase;
  font-weight: 900;
}

/**************/
/*** TABLES ***/
/**************/
.table {
  margin-bottom: 1rem;
  background-color: #e6e6e6;
}

.table .details-ligne-paiements-titre {
  font-size: 18px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.075);
}

.table th, .table td {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: .5rem;
}

.table thead > th, .table thead > td {
  background-color: #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
}

.table thead th {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.table.table-bordered {
  border: 1px solid white;
  background-color: white;
}

.table.table-bordered th, .table.table-bordered td {
  border: 1px solid #e6e6e6;
}

.table-active > td, .table-active > th {
  background-color: #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
}

.table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #e6e6e6;
}

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #8dd09c;
}

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #84ced9;
}

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffdb6e;
}

/**************/
/*** Panier ***/
/**************/
.panier .line-separator {
  border: 1px dashed #cccccc;
  margin: 2rem 0px;
}

.panier .amount-separator {
  border: 1px solid #cccccc;
  margin: 0.5rem 0px;
}

.panier .total {
  font-size: 1.5rem;
}

.panier .paiement-total {
  padding: 10px;
  border: 3px solid;
  font-size: 20px;
}

.panier .paiement-total.primary {
  border-color: #067f9f;
  color: #067f9f;
}

.panier .paiement-total.success {
  border-color: #5cb85c;
  color: #5cb85c;
}

.panier .paiement-total.info {
  border-color: #5bc0de;
  color: #5bc0de;
}

.panier .paiement-total.warning {
  border-color: #f0ad4e;
  color: #f0ad4e;
}

.panier .paiement-total.danger {
  border-color: #d9534f;
  color: #d9534f;
}

.panier .steps .step {
  float: left;
  background: #cccccc;
  padding: 5px 0;
  border-radius: Opx;
  text-align: center;
  width: 150px;
  position: relative;
}

.panier .steps .step_line {
  margin: 0;
  width: 0;
  height: 0;
  border-left: 17px solid #cccccc;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  z-index: 1008;
  position: absolute;
  left: 149px;
  top: 0px;
}

.panier .steps .step_line.backline {
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  z-index: 1006;
  position: absolute;
  left: 149px;
  top: -3px;
}

.panier .steps .step_line.step_current {
  background: 0;
  border-left: 17px solid #067f9f;
}

.panier .steps .step_line.step_complete {
  background: 0;
  border-left: 17px solid #067f9f;
}

.panier .steps .step_current {
  background: #067f9f;
  text-decoration: underline;
  color: white;
}

.panier .steps .step_complete {
  background: #067f9f;
}

.panier .steps .step.check_step {
  margin-left: 5px;
}

.panier .steps .check-bc,
.panier .steps .check-bc:hover {
  color: white;
}

/***************/
/*** VARIOUS ***/
/***************/
.valign-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-muted {
  color: #001934 !important;
  opacity: 0.6;
}

.text-muted2 {
  color: #067f9f;
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: none;
}

.hidden {
  display: none;
}

.fa-star {
  color: #ffdc0f;
}

.promo-resultat {
  padding: 10px;
}

.planning-pdf {
  font-size: 10px;
}

.planning-pdf h2 {
  font-size: 16px;
}

.planning-pdf h3 {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.planning-pdf .well {
  padding: 5px;
}
