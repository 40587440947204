//
// This file is compiled to `/public/build/css/app.css`.
//
// Referencing other assets
// ========================
//
// To reference other assets like images or fonts, refer to them using the full
// path. For example, to reference `images/foo/bar.jpg`:
//
//     background-image: url(/images/foo/bar.jpg);
//
//     // The leading slash is optional, the following also works:
//     // background-image: url(images/foo/bar.jpg);
//
// The compiled CSS will be:
//
//     background-image: url(/assets/images/foo/bar.jpg);
//
// There's no need to use relative paths, they make the code harder to reason about
// and are unnecessary:
//
//     // Don't do this!
//     background-image: url(../../images/foo/bar.jpg);
//
// Partials
// ========
//
// Partial Sass files contain snippets of reusable CSS that you can include in
// other Sass files. A partial is simply a Sass file named with a leading
// underscore, for example `_foo.scss`.
//
// Since partials are meant to be imported by other Sass files, they are not
// compiled on their own so they won't appear under `/public/build`.
//
// To import the file `./partials/_foo.scss`, you would do:
//
//   @import "partials/foo";
//

@import "variables";

body {
    &.background-image {
        background: url("../images/body_bg_img.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;    
    }
}

.main-container {
    background-color: $main-bg-color;
    padding-top: 2rem;
    margin-top: 5rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;    
    text-transform: uppercase;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

a {
    outline: 0;
}
/***************/
/*** BUTTONS ***/
/***************/
.btn {
    border-radius: 0; 
    &.btn-primary {
        color: $darkBlue;
        background-color: $argent;
        border-color: $argent; 
        border-radius: 0;   
    }      
    &.btn-primary:hover {
        color: $darkBlue;
        background-color: darken($argent, 15%);
        border-color: darken($argent, 15%);
    }
    
    &.btn-secondary {
        color: white;
        background-color: $bleuAcier;
        border-color: $bleuAcier;  
        border-radius: 0; 
    }      
    &.btn-secondary:hover {
        color: white;
        background-color: darken($bleuAcier, 15%);
        border-color: darken($bleuAcier, 15%);
    }
}

/**************/
/*** BADGES ***/
/**************/
.badge {
    border-radius: 0;
    &.badge-info {
        background-color: $badge-info;
        color: $darkBlue;
    }    
    &.badge-success {
        background-color: $badge-success;
        color: $darkBlue;
    }    
    &.badge-warning {
        background-color: $badge-warning;
        color: $darkBlue;
    }    
    &.badge-primary {
        font-size: $font-size-base * 0.9;
        color: $darkBlue;
        padding: 0;
        background-color: transparent; 
    }    
    &.badge-secondary {
        font-weight: $font-weight-base;
        color: white;
        background-color: $bleuAcier;  
        font-size: $font-size-base;
        line-height: $line-height-base;
    }
    &.badge-secondary-options {
        font-weight: $font-weight-base;
        font-size: $font-size-base;
        line-height: $line-height-base;
    }    
    &.badge-tertiary {
        font-weight: $font-weight-base; 
        font-size: $font-size-base;
        line-height: $line-height-base;
    }
    &.badge-stage {
        white-space: normal;
    }      
}

/**************/
/*** PICTOS ***/
/**************/
.picto-wave:before {
    content: "";
    display: inline-block;
    background: url("../images/picto-wave.png") no-repeat;    
    float: left;
    width: 35px;
    height: 30px;
    margin: 5px 6px 0 0;
}

/*************/
/*** NAVBAR ***/
/*************/
.navbar {
    .nav-link .fa-user, nav-link .fa-shopping-cart {
        color: $bleuAcier !important;
    }
    &.bg-light {
        background-color: $navbar-bg !important;    
    }
    &.navbar-light .navbar-text {
        color: $navbar-text; 
    }
    &.navbar-light .navbar-text a {
        color: $navbar-text-link; 
    }
    &.navbar-light .navbar-text a:hover, &.navbar-light .navbar-text a:focus {
        color: $navbar-text-link-over;
        text-decoration: none;
    }
    &.navbar-light .navbar-nav .nav-link {
        color: $navbar-nav-link;
    }
    &.navbar-light .navbar-nav .nav-link:hover, &.navbar-light .navbar-nav .nav-link:focus {
        color: $navbar-nav-link-over;
    } 
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-toggler {
        background-color: $navbar-toggler;
    } 
    .navbar-collapse {
        &.show {
            border-top: 1px solid $navbar-collapse-border;
        }    
    }   
}

/*************/
/*** CARDS ***/
/*************/
.card {
    border-radius: 0;
    .card-header {
        border-radius: 0;
    }
    &.stage-critere, &.stage-item {
        background-color: $card-bg-color;
        border: 0px solid $card-border-color;  
       .card-header {
            background-color: $card-bg;
            border-bottom: $card-border-bottom solid $card-border-color;
        }
    }
    &.stage-item .card-header {
        color: white;
    }
    .card-body {
        padding: 0;
        .card-footer {
            padding: 3rem 0.3rem;
            background-color: $card-bg-color;
            border-top: 1px solid $card-bg-color;
        }
    }    
    &.connexion-client, &.paiement-client, &.mon-compte, &.panier-content, &.card-form, &.place-stage {
        background-color: $card-bg-color;
        border: 1px solid $card-border-color;
       .card-header {
            background-color: $card-bg;
            border-bottom: $card-border-bottom solid $card-border-color;
            padding: 0.75rem 1rem;
        }    
        .card-body {
            padding: 1rem;
            .detail-panier {
                .options-reservation {
                        background-color: white;
                }
                .btn {
                    vertical-align: baseline;
                }
            }
        }
    }
    &.mon-compte {
        .card-body {
            .legend_item {
                height: 15px;
                width: 35px;
                margin-right: 10px;
                float: left;
            } 
        }                
        .card {
            background-color: $card-bg-color;
            margin: 1rem;
            .card-body {
                padding: 1rem;       
            }
        }  
    } 
}

/*************/
/*** FORMS ***/
/*************/
form {
    .form-control {
        background-color: $form-control-bg;
        border-radius: 0;
    }
    .custom-control-label::before {
        border-radius: 0 !important;
    }
    .required-star {
        color: red;
    }
}
.stage-view form .custom-control-inline {
    margin-right: .5rem;    
}
.stage-critere .custom-label,
.stage-critere legend.col-form-label {
    text-transform: uppercase;  
    font-weight: 900;  
}          

/**************/
/*** TABLES ***/
/**************/
.table {
    margin-bottom: $table-mb;
    background-color: $table-bg;
    .details-ligne-paiements-titre {
        font-size: 18px;
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.075);
    }
    th, td {
        border-top: 1px solid $table-border;
        border-bottom: 1px solid $table-border;
        padding: .5rem;
    } 
    thead {
        > th, > td {
            background-color: $table-bg;  
            border-bottom: 2px solid $table-border;          
        }
        th {
            border-top: 1px solid $table-bg;
            border-bottom: 1px solid $table-bg;
        }
    }
    &.table-bordered {
        border: 1px solid $table-bordered-bg; 
        background-color: $table-bordered-bg;
        th, td {
            border: 1px solid $table-border;  
        }
    }    
}
.table-active {
    > td, > th {
        background-color: $table-bg;
        border-bottom: 2px solid $table-border;
    }
}

.table-success {
    > td, > th {
        background-color: $table-success;
    }     
}

.table-info {
    > td, > th {
        background-color: $table-info;
    }     
}

.table-warning {
    > td, > th {
        background-color: $table-warning;
    }     
}

.table-hover {
    .table-active:hover {
        > td, > th {
            background-color: $table-bg;
        } 
    }    
    .table-success:hover {
        > td, > th {
            background-color: darken($table-success, 15%);
        }
    }
    .table-info:hover {
        > td, > th {
            background-color: darken($table-info, 15%);
        }
    }    
   .table-warning:hover {
        > td, > th {
            background-color: darken($table-warning, 15%);
        }
    }
}

/**************/
/*** Panier ***/
/**************/
.panier {
    .line-separator {
        border: 1px dashed $lightGrey;
        margin: 2rem 0px;
    }    
    .amount-separator {
        border: 1px solid $lightGrey;
        margin: 0.5rem 0px;
    }
    .total {
        font-size: 1.5rem;
    } 
    .paiement-total {
        padding: 10px;
        border: 3px solid;
        font-size: 20px;
        &.primary {
            border-color: $bleuAcier;
            color: $bleuAcier;
        }
        &.success {
            border-color: $paiement-success;
            color: $paiement-success;
        }
        &.info {
            border-color: $paiement-info;
            color: $paiement-info;
        }
        &.warning {
            border-color: $paiement-warning;
            color: $paiement-warning;
        }
        &.danger {
            border-color: $paiement-danger;
            color: $paiement-danger;
        }
    }
    .steps {
        .step {
            float: left;
            background: $step;
            padding: 5px 0;
            border-radius: Opx;
            text-align: center;
            width: 150px;
            position: relative;
        }
        .step_line {
            margin: 0;
            width: 0;
            height: 0;
            border-left: 17px solid $lightGrey;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            z-index: 1008;
            position: absolute;
            left: 149px;
            top: 0px;
            &.backline {
                border-left: 20px solid white;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                z-index: 1006;
                position: absolute;
                left: 149px;
                top: -3px
            }
            &.step_current {
                background: 0;
                border-left: 17px solid $step-current;
            }
            &.step_complete {
                background: 0;
                border-left: 17px solid $step-current;
            }
        }
        .step_current {
            background: $step-current;
            text-decoration: underline;
            color: white;
        }
        .step_complete {
            background: $step-current;
        }
        .step.check_step {
            margin-left: 5px;
        }
        .check-bc,
        .check-bc:hover {
            color: white;
        }
    }
}

/***************/
/*** VARIOUS ***/
/***************/
.valign-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-muted {
    color: $darkBlue !important; 
    opacity: 0.6;
}
.text-muted2 {
    color: $bleuAcier; 
}
.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 20px;
    display:none;
}
.hidden {
    display:none
}
.fa-star {
    color: #ffdc0f;    
}
.promo-resultat {
    padding: 10px;
}
.planning-pdf {
    font-size: 10px;
    h2 {
        font-size: 16px;
    }
    h3 {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .well {
        padding: 5px;
    }
}



